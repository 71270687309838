<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<el-tabs v-model="active" @tab-click="handleClick">
			<el-tab-pane label="耗材领用汇总表" :name="1"></el-tab-pane>
			<el-tab-pane label="耗材领用明细表" :name="2"></el-tab-pane>
		</el-tabs>

		<template v-if="active == 1">
			<el-table  :data="tableData" border row-key="id" :row-style="{height:'20px'}"
				:default-expand-all="false" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column prop="name" label="领用部门">
				</el-table-column>
				<el-table-column header-align="center" label="领用">
					<el-table-column prop="receive_number" empty-text="-" label="领用数量">
						<template slot-scope="scope">
							<span>{{scope.row.receive_number || '-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="receive_prices" label="领用金额">
						<template slot-scope="scope">
							<span>{{scope.row.receive_prices || '-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column header-align="center" label="退库">
					<el-table-column prop="return_number" label="退库数量">
						<template slot-scope="scope">
							<span>{{scope.row.return_number || '-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="return_prices" label="退库金额">
						<template slot-scope="scope">
							<span>{{scope.row.return_prices || '-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column header-align="center" label="净出库">
					<el-table-column prop="ex_number" label="净领用数量">
						<template slot-scope="scope">
							<span>{{scope.row.ex_number || '-'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="ex_prices" label="净领用金额">
						<template slot-scope="scope">
							<span>{{scope.row.ex_prices || '-'}}</span>
						</template>
					</el-table-column>
				</el-table-column>
			</el-table>
		</template>
		<template v-if="active == 2">
			<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<div  style="position: absolute;right:20px;z-index: 999;">
					<el-button  type="primary" @click="handleExport">导出全部</el-button> 
				</div>
			    <el-form-item label="">
			        <el-input v-model="search_key.keyword" placeholder="请输入派发单号搜索" style="width:200px;"></el-input>
			    </el-form-item>
			    <el-button type="primary" @click="search()">查询</el-button>
			</el-form>
			
			<el-table :data="tableData" border  :key="Math.random()">
				<el-table-column prop="user_name" label="领用部门">
				</el-table-column>
				<el-table-column prop="receipt_number" label="派发单号">
				</el-table-column>
				<el-table-column prop="remark" label="派发备注">
				</el-table-column>
				<el-table-column prop="staff_receipt_number" empty-text="-" label="耗材领用申请单">
				</el-table-column>
				<el-table-column prop="cse_name" label="物料名称">
				</el-table-column>
				<el-table-column prop="cate_name" label="物料分类">
				</el-table-column>
				<el-table-column header-align="center" label="领用">
					<el-table-column prop="receive_number" label="领用数量">
					</el-table-column>
					<el-table-column prop="receive_prices" label="领用金额">
					</el-table-column>
				</el-table-column>
			 </el-table>
			 <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		</template>
	
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				changeStatus:false,
				active: 1,
				tableData: [],
				currentPage:1,
				pagesize:10,
				total:0,
				search_key:{
					keyword:''
				}
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData() {
				this.$api.post('CseReport/receive', {
					type: this.active,
					page:this.currentPage-1,
					limit:this.pagesize,
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.changeStatus = !this.changeStatus
						this.tableData = res.data.list
						this.total =  res.data.count		
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			search(){
				this.currentPage  = 1
				this.getData()
			},
			getCurrPage(val){   //获取当前页
			    this.currentPage =  val
			    this.getData()
			},
			getPageSize(val){   //获取每页条数
			    this.pagesize =  val
			     this.getData()
			},	
			handleClick(val) {
				this.getData()
			},
			handleExport(){
				location.href = '/api/admin/CseReport/exportReceive'
			}
		},
	}
</script>
<style scoped>

</style>
